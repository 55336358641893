@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
  height: 100%;
}
#mainTag{
  
  /* overflow:auto; */
  height: 100vh;
  /* padding-bottom: 100px; */

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.controls {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding-left: 10px;
  padding-bottom: 10px;
}

.counter {
  width: 150px !important;
  margin: 16px !important;
  
  display: -webkit-flex !important;
  
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.counter .qtyField {
  width: 35px !important;
  border: 0;
  line-height: 0px !important;
  font-size: 20px;
  text-align: center;
  height: 2rem !important;
  background: #e6e5e5 !important;
  padding: 0 8px !important;
  border-radius: 5px !important;
  -webkit-appearance: none;
          appearance: none;
  margin-bottom: 0 !important;
  margin-left: 0px !important;
  outline: 0;
}

.counter span {
  display: block;
  font-size: 25px;
  /* padding: 0px 7px; */
  width: 25px !important;
  height: 25px !important;
  margin: 0 auto !important;
  cursor: pointer;
  box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.control-counters {
  display: 'flex';
  -webkit-align-items: 'center';
          align-items: 'center';
  -webkit-justify-content: 'space-around';
          justify-content: 'space-around'; 
}

.cardCount{
  outline: none;
  box-shadow: none;
  border-bottom: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}
.cardCount::after{
  outline: none;
  box-shadow: none;
  border-bottom: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}
.cardCount:focus{
  outline: none !important;
  box-shadow: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}

.button-control{
  background-color: #e4e4e4;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.filterForm{
    margin: 16px 16px !important;
}

.menuContainer{
    height: 100%;
}
.tableCode {
    position: absolute !important;
    top: 50%;
    left: 50%;    
    -webkit-transform: translate(-50%, -50%);    
            transform: translate(-50%, -50%);
    z-index: 1 !important;
    /* font-family: 'Poiret One' !important; */
    font-weight: light !important;
    /* color: #1c1c1c !important; */
    -webkit-user-select: none;   
    -ms-user-select: none;      
    user-select: none;     
    pointer-events: none;          
      
}

.tableContainer{
    position: relative;
}

.tableContainer:hover {
    -webkit-filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
            filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
    cursor: pointer;
    /* color: white !important; */
}

.tableContainerSelected {
    -webkit-filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
            filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
    cursor: pointer;
}

.resConfirmed{
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.seating:hover{
    /* color: white !important; */
}

.bookingArea{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.responsiveBooking{
    position: relative;
    width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .bookingArea{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        margin-left: 250px !important;
    }
    .seating{
        width: 80px;
        padding: 8px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .bookingArea{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        margin-left: 100px !important;
    }
    .seating{
        width: 120px;
        padding: 8px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.responsive-img{
    margin-top: 16px;
    width: 100%;
}
@media (min-width: 600px) {
    .MuiGrid-grid-sm-3 {
        -webkit-flex-grow: 0;
                flex-grow: 0;
        max-width: 100% !important;
        -webkit-flex-basis: 25%;
                flex-basis: 25%;
    }
}
.pageContainer {
    display: -webkit-flex;
    display: flex;
}

.tabPanelContainer{
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.sidePanelCard{
    width: 300px;
    height: 100vh;
    background-color: 'whitesmoke';
}
.panelText{
    text-align: center !important;
    padding-top: 16px  !important;
    
}
.sidePanelCard h4{
    font-family: 'Poiret One';
}

.tabs{
    text-align: right !important;
}

.tabPanel{
    /* height: 100vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    /* overflow-y: scroll; */
}

.mobileTabPanel{

}

.tabPanelAlt{
    /* height: 100vh; */
    width: 100%;
    display: -webkit-flex;
    display: flex;
    
}
.tabContent{
    /* overflow: scroll; */
}
.cardStyle{
    width: 700px;
    height: 300px;
    margin: 20px auto;
}

@media only screen and (max-width: 600px) {
    .cardStyle{
        width: 100%;
        height: 350px;
        margin: 20px auto;
    }
}

.detailsButton{
    float: right;
}

.headerStyle {
    font-family: 'Poiret One' !important;
    margin-bottom: 64px !important;
}
.checkoutItem{
    width: 100%;
    min-width: 300px !important;
    max-width: 500px;
    height: 200px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.card-image-alt{
    height: auto;
    max-width: 80%;
    overflow: hidden;
    object-fit: cover;
    object-position: 40% 10%;
    border-radius: 10px;
}

.listCard{
    /* background-color: yellow !important; */
    width: 70%;
    min-width: 220px !important;

    margin-bottom: 16px !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .checkoutItem{
        width: 100%;
        min-width: 300px !important;
        max-width: 500px;
        height: 125px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}


.orderHistoryCard{
    /* max-width: 460px; */
    margin-bottom: 16px;
    min-width: 300px;
    width: 30vw;
}

.listContainer{
    /* background-color: yellow !important; */
    /* width: ; */
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cartButton{
        display: none !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cartButton{
        display: none !important;
    }
}

.brand-logo{
    padding: 0 8px !important;
    font-size: 18px !important;
}
/* .nav-col{
    padding-right: 16px !important;
} */
.closeButton{
    float: right;
}

.cartItemImage{
    height: 80px !important;
    width: 80px;
    overflow: hidden;
    object-fit: cover;
    object-position: 40% 10%;
    border-radius: 10px;
}

.cartItemCounter {
    display: -webkit-flex !important;
    display: flex !important;
    margin-bottom: 16px;
    -webkit-align-items: center !important;
            align-items: center !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .cartItemCounter button {
    color: white;
  }
  
  .cartItemCounter .qtyField {
    width: 35px !important;
    border: 0;
    line-height: 0px !important;
    font-size: 20px;
    text-align: center;
    height: 2rem !important;
    background: #e6e5e5 !important;
    padding: 0 8px !important;
    border-radius: 5px !important;
    -webkit-appearance: none;
            appearance: none;
    margin-bottom: 0 !important;
    margin-left: 0px !important;
    outline: 0;
  }
  
  .cartItemCounter span {
    display: block;
    font-size: 25px;
    /* padding: 0px 7px; */
    width: 25px !important;
    height: 25px !important;
    margin: 0 auto !important;
    cursor: pointer;
    box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
    color: #ffffff;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
footer{
    position:relative;
    left:0;
    /* bottom:0; */
    right:0;
    clear: both;
    /* bottom: auto; */
    /* height: 300px; */
    margin-top: 35vh; /* To control placement of footer*/
    
    width: 100%;
    background-color: #141414 !important;
    padding-top: 16px;
}

.footer-copyright{
    background-color: #0f0f0f !important;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    /* align-items: center; */
    color: white;
    padding: 16px 0;

}

.footerHeaders{
    font-family: 'Poiret One' !important;
}

#footer-logo
{
    height: 80px;
}

.grid-content{
  text-align: center;
  color: white;
} 

.grid-content p{
    font-size: 18px;
} 


.resCard{
    /* background-color: yellow !important; */
    min-width: 300px;
    width: 50vw;
    height: auto;
    margin-bottom: 16px;
}

.emptyReservationText{
    font-family: 'Open Sans';
    font-weight: 300;
}

.resText{
    font-family: 'Poiret One' !important;
}
.reservationContent{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.cardContainer{
    /* background-color: yellow !important; */
    width: 100%;
}

.reservationDate{
    font-size: 16px !important;
}

.cardHighlight{
    background-color: #039BE5;
    width: 100%;
    height: 5px;
}

.loginCardContainer{
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: whitesmoke;

}

.loginCard{
    text-align: center;
    width: 300px;
    margin: 16px auto;
}

.cardContent{
    padding: 16px 32px !important;
}

.loginCardContent h5{
    font-family: 'Poiret One';
    font-weight: bold;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none !important; */
}

.loginCard input {
    margin-bottom: 8px !important;
  }

.loginButton{
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.createAccount a{
    font-size: 14px;
}
.code, h3{
  font-family: 'Poiret One';
  

}

.notFoundContent{
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}
.registerCardContainer{
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: whitesmoke;

}

.registerCardContent h5{
    font-family: 'Poiret One';
    font-weight: bold;
}

.registerCard{
    text-align: center;
    width: 400px;
    margin: 16px auto;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none !important; */
}

.registerCard input {
    margin-bottom: 8px !important;
  }

.registerButton{
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

.checkoutCard input {
    /* padding: 0 8px !important; */
    
}

.checkoutCard {
    margin: 16px auto !important;
    width: 60%;
}

.modalCard input {
    padding: 0 8px !important;
    -webkit-appearance: none;
            appearance: none;
    margin-bottom: 0 !important;
    margin-left: 0px !important;
    outline: 0;
}

.modalCard {
    width: 50%;
    margin: 16px auto;

}

.radioFieldContainer{
    margin: auto;
    width: 60%;
}




.buttonAlign{
    margin-top: 20px !important;
    
}

.editButton{
    /* float: right; */
}

.submitButton{
    margin: 0 auto;
    margin-top: 16px ;
    width: 60%;
}

@media only screen and (max-width: 600px) {
    .checkoutCard{
        width: 100%;
        /* height: 350px; */
        margin: 20px auto;
    }

    .modalCard{
        width: 100%;
    }
    .radioFieldContainer{
        margin: auto;
        width: 300px !important;
        
    }
}


.addButton{
    margin: 16px 0 !important;
}

@media only screen and (max-width: 600px) {
    .addButtonContainer{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.deliveryCard{
    width: 70%;
    min-width: 220px !important;
}




.homeHeader{
    /* background-color: yellow; */
    height: auto !important;
    position: absolute !important;
    z-index: 1;
    width: 100%;
}

.logoContainer{
    /* background-color: yellow !important; */
    /* height: 150px !important; */
    width: 100% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.logoContainer h2{
    font-family: 'Poiret One';
}
.headerLogo{
    height: 40px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 16px auto;
}
.nav-col{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.nav-col a{
    text-decoration: none;
    color: white;
}
.nav-col li{
    font-size: 16px;
}
.homeText{
    margin-left: 24px;
}

.brand-logo{
    
}

.homeNav{
    margin-right: 24px !important;
    /* float: right; */
    margin: 0;
    padding: 0; 
}

.cartOptions{
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
}

.cartText{
    /* padding: 16px; */
    font-weight: bold;
    /* text-align: center; */
    font-family: 'Poiret One', Arial, Helvetica, sans-serif;
}
.deliveryText{
    padding: 16px;
    font-weight: bold;
    /* text-align: center; */
    font-family: 'Poiret One', Arial, Helvetica, sans-serif;
}
.cartButton{
    padding: 0 !important;
}
html, body{
margin: 0;
padding:0;
height: 100%;
width: 100%;
font-family:'Poppins',sans-serif;
color: #141414ff;
}

.nav-col{
  background-color: rgba(0, 0, 0, 0);
}

main{
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  margin:0 !important;
  padding: 0 !important;
  height: auto !important;
}

h4{
  font-family: "Poiret One";
  font-weight: bold;
}
 input, textarea
{
    border-radius: 10px;
    border: none;
    padding-left: 16px;
    padding-right: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
.form-section input{
  width: 100%;
}

input[type="text"], textarea {

  /* background-color : #f0f0f0;  */

}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none; */
}

.area{
  width: 100%;
  
}
.form-section h1
{
  color: white;
  font-family: 'New Tegomin';
  font-size: 4.5vw;
}
.padding{
  padding: 4;
}

.centered {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


li{
  display: inline-block; padding:10px;
}

ul a:hover{
  color:rgb(23, 118, 191);
  
}

nav {
  background: none !important;
}
*{transition:all 0.3s ease-in-out;}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
}

.altNavbar{
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
}

/* Main Content */

.card.small {
  margin-left: auto;
  margin-right: auto;
  height: 400px !important;
  min-width: 280px !important;
  max-width: 280px !important;
}

.card{
  border-radius: 10px;
  overflow: hidden;
}

.card-image img{
  height: 200px !important;
}

.card-controls{
 padding-top: 32px !important;
 margin: 0 !important;
}

.btn-floating img{
  margin-top: 20%;
  height: 26px;
  
}

.btn-floating {
  padding: 0px;
  margin: 0px;
  
}
.card-title{
  font-family: 'Roboto';
  font-weight: 300;
}

.card img{
  border-radius: 0 0 15px 15px !important;
  
}
.card .row{
  margin: 0 !important;
}



.MuiButtonGroup-contained {
  border-radius: 20px !important;
}
.card .MuiButton-containedSecondary:nth-child(1) {
  border-radius: 20px 0px 0px 20px !important;
  color: white !important;
}

.card .MuiButton-containedSecondary:nth-child(2) {
  border-radius: 0px 20px 20px 0px !important;
  color: white !important;
}



.MuiButton-containedSecondary {
  color: #fff !important;
}

.hero-text{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: 'center';
          justify-content: 'center';
  position: absolute;
  left: 25%;
  right: 50%;
  top: 30%;
  color: white;
  width: 50%;
  
}

.hero-image{
  width: 100%;
}

.heroButton {
  width: auto;
  border-radius: 5px !important;
  color: white !important;
}

/* .grid-content{
  display: flex;
  justify-content: center;
}  */



.main-font{
  font-family: 'Poiret One' !important;
}

.form-size{
  width: 20;
  padding-top: 0;
  padding-bottom: 0;
  text-align: 'center';

}

.homeList{
  display: block;
  width: 100vw;
  margin: 0 auto;
}

/* End of Main Content */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  width: 0px;
  
  background-color: rgb(20, 20, 20);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2196f3; 
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #227ec9; 
}

/* ----------------------------------------------------------- */
/* End of scroll  */







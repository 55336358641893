.tableCode {
    position: absolute !important;
    top: 50%;
    left: 50%;    
    transform: translate(-50%, -50%);
    z-index: 1 !important;
    /* font-family: 'Poiret One' !important; */
    font-weight: light !important;
    /* color: #1c1c1c !important; */
    -webkit-user-select: none;  
    -moz-user-select: none;   
    -ms-user-select: none;      
    user-select: none;     
    pointer-events: none;          
      
}

.tableContainer{
    position: relative;
}

.tableContainer:hover {
    filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
    cursor: pointer;
    /* color: white !important; */
}

.tableContainerSelected {
    filter: sepia(37%) saturate(4667%) hue-rotate(169deg) brightness(89%) contrast(103%);
    cursor: pointer;
}

.resConfirmed{
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
}

.seating:hover{
    /* color: white !important; */
}

.bookingArea{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.responsiveBooking{
    position: relative;
    width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .bookingArea{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-left: 250px !important;
    }
    .seating{
        width: 80px;
        padding: 8px !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .bookingArea{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-left: 100px !important;
    }
    .seating{
        width: 120px;
        padding: 8px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

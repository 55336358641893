/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding-left: 10px;
  padding-bottom: 10px;
}

.counter {
  width: 150px !important;
  margin: 16px !important;
  
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.counter .qtyField {
  width: 35px !important;
  border: 0;
  line-height: 0px !important;
  font-size: 20px;
  text-align: center;
  height: 2rem !important;
  background: #e6e5e5 !important;
  padding: 0 8px !important;
  border-radius: 5px !important;
  appearance: none;
  margin-bottom: 0 !important;
  margin-left: 0px !important;
  outline: 0;
}

.counter span {
  display: block;
  font-size: 25px;
  /* padding: 0px 7px; */
  width: 25px !important;
  height: 25px !important;
  margin: 0 auto !important;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
  -moz-box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
  box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
  color: #ffffff;
  user-select: none;
}

.control-counters {
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-around'; 
}

.cardCount{
  outline: none;
  box-shadow: none;
  border-bottom: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}
.cardCount::after{
  outline: none;
  box-shadow: none;
  border-bottom: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}
.cardCount:focus{
  outline: none !important;
  box-shadow: none !important;
  width: 20px;
  padding-top: 0;
  padding-bottom: 0;
    
}

.button-control{
  background-color: #e4e4e4;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
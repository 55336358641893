@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');

html, body{
margin: 0;
padding:0;
height: 100%;
width: 100%;
font-family:'Poppins',sans-serif;
color: #141414ff;
}

.nav-col{
  background-color: rgba(0, 0, 0, 0);
}

main{
  flex: 1 0 auto;
  margin:0 !important;
  padding: 0 !important;
  height: auto !important;
}

h4{
  font-family: "Poiret One";
  font-weight: bold;
}
 input, textarea
{
    -moz-border-radius: 20px;
    border-radius: 10px;
    border: none;
    padding-left: 16px;
    padding-right: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
.form-section input{
  width: 100%;
}

input[type="text"], textarea {

  /* background-color : #f0f0f0;  */

}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none; */
}

.area{
  width: 100%;
  
}
.form-section h1
{
  color: white;
  font-family: 'New Tegomin';
  font-size: 4.5vw;
}
.padding{
  padding: 4;
}

.centered {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


li{
  display: inline-block; padding:10px;
}

ul a:hover{
  color:rgb(23, 118, 191);
  
}

nav {
  background: none !important;
}
*{transition:all 0.3s ease-in-out;}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
}

.altNavbar{
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
}

/* Main Content */

.card.small {
  margin-left: auto;
  margin-right: auto;
  height: 400px !important;
  min-width: 280px !important;
  max-width: 280px !important;
}

.card{
  border-radius: 10px;
  overflow: hidden;
}

.card-image img{
  height: 200px !important;
}

.card-controls{
 padding-top: 32px !important;
 margin: 0 !important;
}

.btn-floating img{
  margin-top: 20%;
  height: 26px;
  
}

.btn-floating {
  padding: 0px;
  margin: 0px;
  
}
.card-title{
  font-family: 'Roboto';
  font-weight: 300;
}

.card img{
  border-radius: 0 0 15px 15px !important;
  
}
.card .row{
  margin: 0 !important;
}



.MuiButtonGroup-contained {
  border-radius: 20px !important;
}
.card .MuiButton-containedSecondary:nth-child(1) {
  border-radius: 20px 0px 0px 20px !important;
  color: white !important;
}

.card .MuiButton-containedSecondary:nth-child(2) {
  border-radius: 0px 20px 20px 0px !important;
  color: white !important;
}



.MuiButton-containedSecondary {
  color: #fff !important;
}

.hero-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  position: absolute;
  left: 25%;
  right: 50%;
  top: 30%;
  color: white;
  width: 50%;
  
}

.hero-image{
  width: 100%;
}

.heroButton {
  width: auto;
  border-radius: 5px !important;
  color: white !important;
}

/* .grid-content{
  display: flex;
  justify-content: center;
}  */



.main-font{
  font-family: 'Poiret One' !important;
}

.form-size{
  width: 20;
  padding-top: 0;
  padding-bottom: 0;
  text-align: 'center';

}

.homeList{
  display: block;
  width: 100vw;
  margin: 0 auto;
}

/* End of Main Content */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  width: 0px;
  
  background-color: rgb(20, 20, 20);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2196f3; 
  border-radius: 5px;
  transition: 0.3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #227ec9; 
}

/* ----------------------------------------------------------- */
/* End of scroll  */






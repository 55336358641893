.homeHeader{
    /* background-color: yellow; */
    height: auto !important;
    position: absolute !important;
    z-index: 1;
    width: 100%;
}

.logoContainer{
    /* background-color: yellow !important; */
    /* height: 150px !important; */
    width: 100% !important;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.logoContainer h2{
    font-family: 'Poiret One';
}
.headerLogo{
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
}
.nav-col{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-col a{
    text-decoration: none;
    color: white;
}
.nav-col li{
    font-size: 16px;
}
.homeText{
    margin-left: 24px;
}

.brand-logo{
    
}

.homeNav{
    margin-right: 24px !important;
    /* float: right; */
    margin: 0;
    padding: 0; 
}

.cartOptions{
    justify-content: space-between !important;
}

.cartText{
    /* padding: 16px; */
    font-weight: bold;
    /* text-align: center; */
    font-family: 'Poiret One', Arial, Helvetica, sans-serif;
}
.deliveryText{
    padding: 16px;
    font-weight: bold;
    /* text-align: center; */
    font-family: 'Poiret One', Arial, Helvetica, sans-serif;
}
.cartButton{
    padding: 0 !important;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none !important;
}

.checkoutCard input {
    /* padding: 0 8px !important; */
    
}

.checkoutCard {
    margin: 16px auto !important;
    width: 60%;
}

.modalCard input {
    padding: 0 8px !important;
    appearance: none;
    margin-bottom: 0 !important;
    margin-left: 0px !important;
    outline: 0;
}

.modalCard {
    width: 50%;
    margin: 16px auto;

}

.radioFieldContainer{
    margin: auto;
    width: 60%;
}




.buttonAlign{
    margin-top: 20px !important;
    
}

.editButton{
    /* float: right; */
}

.submitButton{
    margin: 0 auto;
    margin-top: 16px ;
    width: 60%;
}

@media only screen and (max-width: 600px) {
    .checkoutCard{
        width: 100%;
        /* height: 350px; */
        margin: 20px auto;
    }

    .modalCard{
        width: 100%;
    }
    .radioFieldContainer{
        margin: auto;
        width: 300px !important;
        
    }
}
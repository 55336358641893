.registerCardContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;

}

.registerCardContent h5{
    font-family: 'Poiret One';
    font-weight: bold;
}

.registerCard{
    text-align: center;
    width: 400px;
    margin: 16px auto;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none !important; */
}

.registerCard input {
    margin-bottom: 8px !important;
  }

.registerButton{
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}
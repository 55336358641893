/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .cartButton{
        display: inline !important;
        margin-top: 8px !important;
        float: right !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .cartButton{
        display: none !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .cartButton{
        display: none !important;
    }
}

.brand-logo{
    padding: 0 8px !important;
    font-size: 18px !important;
}
/* .nav-col{
    padding-right: 16px !important;
} */
.cardStyle{
    width: 700px;
    height: 300px;
    margin: 20px auto;
}

@media only screen and (max-width: 600px) {
    .cardStyle{
        width: 100%;
        height: 350px;
        margin: 20px auto;
    }
}

.detailsButton{
    float: right;
}

.headerStyle {
    font-family: 'Poiret One' !important;
    margin-bottom: 64px !important;
}
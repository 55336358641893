.resCard{
    /* background-color: yellow !important; */
    min-width: 300px;
    width: 50vw;
    height: auto;
    margin-bottom: 16px;
}

.emptyReservationText{
    font-family: 'Open Sans';
    font-weight: 300;
}

.resText{
    font-family: 'Poiret One' !important;
}
.reservationContent{
    display:flex;
    justify-content: space-around;
}

.cardContainer{
    /* background-color: yellow !important; */
    width: 100%;
}

.reservationDate{
    font-size: 16px !important;
}

.cardHighlight{
    background-color: #039BE5;
    width: 100%;
    height: 5px;
}

.loginCardContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;

}

.loginCard{
    text-align: center;
    width: 300px;
    margin: 16px auto;
}

.cardContent{
    padding: 16px 32px !important;
}

.loginCardContent h5{
    font-family: 'Poiret One';
    font-weight: bold;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline: none !important; */
}

.loginCard input {
    margin-bottom: 8px !important;
  }

.loginButton{
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.createAccount a{
    font-size: 14px;
}
.checkoutItem{
    width: 100%;
    min-width: 300px !important;
    max-width: 500px;
    height: 200px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.card-image-alt{
    height: auto;
    max-width: 80%;
    overflow: hidden;
    object-fit: cover;
    object-position: 40% 10%;
    border-radius: 10px;
}

.listCard{
    /* background-color: yellow !important; */
    width: 70%;
    min-width: 220px !important;

    margin-bottom: 16px !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .checkoutItem{
        width: 100%;
        min-width: 300px !important;
        max-width: 500px;
        height: 125px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
   
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
}


.closeButton{
    float: right;
}

.cartItemImage{
    height: 80px !important;
    width: 80px;
    overflow: hidden;
    object-fit: cover;
    object-position: 40% 10%;
    border-radius: 10px;
}

.cartItemCounter {
    display: flex !important;
    margin-bottom: 16px;
    align-items: center !important;
    justify-content: center !important;
  }
  .cartItemCounter button {
    color: white;
  }
  
  .cartItemCounter .qtyField {
    width: 35px !important;
    border: 0;
    line-height: 0px !important;
    font-size: 20px;
    text-align: center;
    height: 2rem !important;
    background: #e6e5e5 !important;
    padding: 0 8px !important;
    border-radius: 5px !important;
    appearance: none;
    margin-bottom: 0 !important;
    margin-left: 0px !important;
    outline: 0;
  }
  
  .cartItemCounter span {
    display: block;
    font-size: 25px;
    /* padding: 0px 7px; */
    width: 25px !important;
    height: 25px !important;
    margin: 0 auto !important;
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
    -moz-box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
    box-shadow: 10px 10px 35px -9px rgba(0,0,0,0.57);
    color: #ffffff;
    user-select: none;
  }